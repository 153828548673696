<div class="w-full bg-white shadow-lg hover:shadow-xl hover:-translate-y-1 transition-all transform duration-700 ease-in-out group rounded-lg py-4 px-2 sm:p-4 md:p-6 expander">
  <div class="w-full flex flex-row flex-wrap items-center">
    <div class="w-full mb-2 md:mb-0 md:w-2/5 lg:w-3/6 flex flex-row">
      <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-gray-400">
        <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
      </div>
      <div class="flex-1 pl-3">
        <div class="w-full">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
                'border-radius': '5px',
                height: '16px',
                width: '25%',
                'background-color': '#efefef'
              }"
          ></ngx-skeleton-loader>
        </div>
        <div class="w-full">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
                'border-radius': '5px',
                height: '18px',
                width: '60%',
                'background-color': '#efefef'
              }"
        ></ngx-skeleton-loader>
        </div>
        <div class="w-full">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
                'border-radius': '5px',
                height: '10px',
                width: '10%',
                'background-color': '#efefef'
              }"
        ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="w-full mb-2 md:mb-0 md:w-2/5 lg:w-2/6 md:px-4">
      <div class="w-full">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
                'border-radius': '5px',
                height: '16px',
                width: '95%',
                'background-color': '#efefef'
              }"
      ></ngx-skeleton-loader>
      </div>
      <div class="w-full">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
                'border-radius': '5px',
                height: '16px',
                width: '95%',
                'background-color': '#efefef'
              }"
      ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="w-full md:w-1/5 lg:w-1/6 flex flex-row flex-wrap items-center justify-end">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
                'border-radius': '5px',
                height: '18px',
                width: '95%',
                'background-color': '#efefef'
              }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
